import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import {
  makeStyles,
  styled,
} from '@material-ui/core/styles'
import { spacing } from '@material-ui/system'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import globalStyles from 'src/styles/globalStyles'

import {
  Button as MuiButton,
  Container as MuiContainer,
  Hidden,
  Typography,
  Grid as MuiGrid,
  Box,
  AppBar,
  Toolbar,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  ButtonGroup,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Tabs,
  Tab
} from '@material-ui/core'

import Layout from 'src/components/layout'
import Assessment from 'src/components/aboutUs/assessment'
import { SectionHeader } from 'src/components/headers'

import 'swiper/swiper.scss'
import 'src/styles/swiper.scss'
import SwiperCore, {
  Autoplay as SwiperAutoplay,
  Navigation as SwiperNavigation,
  Pagination as SwiperPagination
} from 'swiper/core'
import { Swiper, SwiperSlide } from 'swiper/react'
import { parseAlt } from 'src/helpers'

const Button = styled(MuiButton)(spacing)
const Container = styled(MuiContainer)(spacing)
const Grid = styled(MuiGrid)(spacing)

SwiperCore.use([SwiperAutoplay, SwiperNavigation, SwiperPagination])

const useStyles = makeStyles(theme => ({
  ...globalStyles,
  bannerSlider: {
    zIndex: '100',
    width: '100%',
    backgroundColor: '#606060',
  },
  bannerSliderImg: {
    position: 'absolute',
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    opacity: '0.4',
  },
  bannerHeader: {
    height: '100%',
    zIndex: '1000',
    position: 'relative',
    color: 'white',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'flex-end',
    textAlign: 'center'
  },
  bannerHeading: {
    fontSize: '3rem'
  },
  bannerHeadline: {
    display: 'inline-block'
  },
  bannerSubheader: {
    fontSize: '20px'
  },
  map: {
    overflow: 'hidden',
  },
  spaceBetween: {
    justifyContent: 'space-between'
  },
  countrySeparator: {
    height: '3px',
    backgroundColor: 'black',
    marginBottom: '18px'
  },
  country: {
    fontSize: '0.9rem'
  },
  textHighlight: {
    backgroundColor: '#50ade5',
    padding: '0px 3px'
  },
  trucksSlider: {
    width: '100%',
    borderRadius: '4px',
  },
  formControl: {
    minWidth: '200px'
  },
  smallSelect: {
    '& div': {
      padding: '10px 14px'
    }
  },
  [theme.breakpoints.up('xs')]: {
    bannerSlider: {
      height: '400px',
    }
  },
  [theme.breakpoints.down('sm')]: {
    bannerHeading: {
      fontSize: '2.2rem'
    },
  },
  [theme.breakpoints.up('md')]: {
    bannerHeader: {
      textAlign: 'left'
    },
    country: {
      fontSize: '1rem'
    },
  },
  [theme.breakpoints.up('lg')]: {
    bannerSlider: {
      height: '600px'
    }
  },
  slide: {
    overflow: 'hidden'
  },
  flag: {
    borderRadius: '4px',
    border: 'solid 1px #c4c4c4'
  },
  justify: {
    textAlign: 'justify'
  },
  trucksNav: {
    paddingBottom: theme.spacing(1),
    width: '100%',
    '& button': {
      width: '50%'
    }
  },
  bgGradient: {
    color: 'white',
    backgroundColor: '#3871c2',
    // background: 'rgb(56,113,194)',
    // background: 'linear-gradient(137deg, rgba(56,113,194,1) 55%, rgba(80,173,229,1) 100%)'
  },
  missionPicture: {
    opacity: '1'
  },
  reviewCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& p': {
      textAlign: 'center',
      marginTop: '0px',
      marginBottom: '10px'
    },
    '& span': {
      fontWeight: '500',
      fontSize: '1.2rem',
      textAlign: 'center'
    },
  },
  client: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '200px',
    paddingBottom: '50px'
  },
  logo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& span': {
      fontSize: '0.8rem',
      fontWeight: '500',
      color: 'grey'
    }
  }
}))

const AboutUs = ({ data, pageContext }) => {
  const AboutUs = data.contentfulAboutUs
  const classes = useStyles()
  const theme = useTheme()
  const bptMd = useMediaQuery(theme.breakpoints.up('md'))

  const flags = {
    pl: 'iVBORw0KGgoAAAANSUhEUgAAAKAAAABkAQMAAAA7eKnMAAAABlBMVEXcFDz////vuDXSAAAAHUlEQVRIx2P4jwUwjAqOCo4KjnDBUTAKRsEowAAAvvvkRjRgx8kAAAAASUVORK5CYII=',
    de: 'iVBORw0KGgoAAAANSUhEUgAAAKAAAABgAgMAAADnSZEKAAAACVBMVEXdAAAAAAD/zgCNyoGDAAAAKUlEQVRIx+3KIQEAAAgDMEpSkpQUQOC/6VU/lSiKYlgE4DZPoiiKYXEBPif7Pajsl/wAAAAASUVORK5CYII='
  }

  const [ourFleet, setOurFleet] = useState(AboutUs.fleetEntries[0])

  return (
    <Layout pageContext={pageContext}>
      <Swiper className={classes.bannerSlider} pagination={{ clickable: true }} speed={1000} autoplay={{ delay: 4000, disableOnInteraction: true }}>
        {AboutUs.bannerSlider.map(slide => (
          <SwiperSlide className={classes.slide} key={slide.id}>
            <GatsbyImage className={classes.bannerSliderImg} image={getImage(slide.picture)} alt={parseAlt(slide.picture, pageContext)} />
            <Container className={classes.bannerHeader} pb={12}>
              <div>
                <span className={`${classes.bannerHeading} ${classes.boldText} ${classes.headerLine}`}>{slide.title}</span>
              </div>
              <p className={`${classes.boldText} ${classes.bannerSubheader}`}>{slide.caption}</p>
            </Container>
          </SwiperSlide>
        ))}
      </Swiper>

      <Container pt={8}>
        <Grid className={classes.spaceBetween} container spacing={8}>
          <Grid item xs={12} md={7} lg={6}>
            <SectionHeader>{AboutUs.transport}</SectionHeader>
            <Box className={classes.justify}>
              {AboutUs.transportParagraph1 ? (
                documentToReactComponents(JSON.parse(AboutUs.transportParagraph1.raw))
              ) : ''}
            </Box>
            <Box>
              <p className={`${classes.highlight} ${classes.boldText}`}>{AboutUs.transportSubheader1}</p>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box textAlign="center" width={bptMd ? 80 : 60}>
                  <img className={classes.flag} src={`data:image/png;base64,${flags.pl}`} width="100%" />
                  <span className={`${classes.country} ${classes.boldText}`}>{AboutUs.transportPoland}</span>
                </Box>
                <Box className={classes.countrySeparator} width={bptMd ? 50 : 20}></Box>
                <Box textAlign="center" width={bptMd ? 80 : 60}>
                  <img className={classes.flag} src={`data:image/png;base64,${flags.de}`} width="100%" />
                  <span className={`${classes.country} ${classes.boldText}`}>{AboutUs.transportGermany}</span>
                </Box>
                <Box className={classes.countrySeparator} width={bptMd ? 50 : 20}></Box>
                <Box textAlign="center" width={bptMd ? 80 : 60}>
                  <img className={classes.flag} src="/es.png" width="100%"></img>
                  <span className={`${classes.country} ${classes.boldText}`}>{AboutUs.transportSpain}</span>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={5} lg={4}>
            <Box borderRadius={4} className={`${classes.map}`}>
              <GatsbyImage image={getImage(AboutUs.transportMap)} alt={parseAlt(AboutUs.transportMap, pageContext)} />
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Container mt={8} pb={8}>
        <SectionHeader>{AboutUs.fleet}</SectionHeader>
        <Grid container spacing={bptMd ? 6 : 1} pt={2}>
          <Grid item xs={12} md={6}>
            <ButtonGroup className={classes.trucksNav} color="primary">
              {AboutUs.fleetEntries.map(item => (
                <Button variant={ourFleet.id === item.id ? 'contained' : 'outlined'} onClick={() => setOurFleet(item)} size="large" disableRipple disableTouchRipple disableFocusRipple disableElevation key={item.id}>{item.title}</Button>
              ))}
            </ButtonGroup>
            <ul className={classes.ulist}>
              {ourFleet.features.map(item => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          </Grid>
          <Grid item xs={12} md={6}>
            <Swiper className={classes.trucksSlider} navigation pagination={{ clickable: true }} loop>
              {ourFleet.pictures.map(picture => (
                <SwiperSlide key={picture.id}>
                  <GatsbyImage image={getImage(picture)} alt={parseAlt(picture, pageContext)} />
                </SwiperSlide>
              ))}
            </Swiper>
          </Grid>
        </Grid>
      </Container>

      <div className={classes.bgGradient}>
        <Container py={6}>
          <Grid container spacing={bptMd ? 8 : 1}>
            <Grid item xs={12} md={4}>
              <SectionHeader white>{AboutUs.mission}</SectionHeader>
              <p className={`${classes.justify} ${classes.boldText}`}>{AboutUs.missionParagraph1}</p>
              <p className={`${classes.justify} ${classes.boldText}`}>{AboutUs.missionParagraph2}</p>
            </Grid>
            <Grid item xs={12} md={8}>
              <GatsbyImage className={`${classes.rounded} ${classes.missionPicture}`} image={getImage(AboutUs.missionPicture)} alt={parseAlt(AboutUs.missionPicture, pageContext)} />
            </Grid>
          </Grid>
        </Container>
      </div>

      <Container mt={8}>
        <SectionHeader>{AboutUs.clients}</SectionHeader>
        <Grid container pt={8} pb={8} spacing={8}>
          {AboutUs.clientsReviews.map(client => (
            <Grid className={classes.reviewCard} item xs={12} sm={6} md={4} key={client.id}>
              <Box display="flex" flexDirection="column" flexGrow={1} justifyContent="center">
                {client.review ? (
                  documentToReactComponents(JSON.parse(client.review.raw))
                ) : ''}
                <span>{client.caption ? client.caption : ''}</span>
              </Box>
              <Box display="flex" flexDirection="column" alignItems="center" pt={5}>
                <GatsbyImage image={getImage(client.logo)} width="180px" alt={parseAlt(client.logo, pageContext)} />
              </Box>
            </Grid>
          ))}
        </Grid>
        <Swiper className={classes.swiperBullets} slidesPerView={bptMd ? 4 : 2} spaceBetween={30} pagination={{ clickable: true }} autoplay={{ delay: 1500 }} loop>
          {AboutUs.clientsList.map(client => (
            <SwiperSlide key={client.id}>
              <div className={classes.client}>
                <Box width="50%">
                  <GatsbyImage image={getImage(client)} alt={parseAlt(client, pageContext)} />
                </Box>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
      <Container mt={8}>
        <SectionHeader>{AboutUs.awards}</SectionHeader>
        <Grid container pt={5} pb={4}>
          {AboutUs.awardsList.map(award => (
            <Grid item xs={6} sm={4} md={3} className={classes.logo} key={award.id}>
              <Box width="50%" pt={2} pb={1} px={2}>
                <GatsbyImage image={getImage(award.logo)} alt={parseAlt(award.logo, pageContext)} />
              </Box>
              {award.caption ? (
                <span style={{ padding: '10px 0px', textAlign: 'center' }}>{award.caption}</span>
              ) : ''}
            </Grid>
          ))}
        </Grid>
      </Container>

      <Assessment pageContext={pageContext} assessment={AboutUs.assessment} />
    </Layout>
  )
}

export default AboutUs

export const pageQuery = graphql`
  query AboutUsQuery($id: String!) {
    contentfulAboutUs(id: { eq: $id }) {
      bannerSlider {
        id
        title
        caption
        picture {
          description
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      transport
      transportParagraph1 {
        raw
      }
      transportSubheader1
      transportPoland
      transportGermany
      transportSpain
      transportMap {
        description
        gatsbyImageData(layout: CONSTRAINED)
      }
      fleet
      fleetEntries {
        id
        title
        features
        pictures {
          id
          description
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      mission
      missionParagraph1
      missionParagraph2
      missionPicture {
        description
        gatsbyImageData(layout: CONSTRAINED)
      }
      clients
      clientsReviews {
        id
        caption
        review {
          raw
        }
        logo {
          description
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      clientsList {
        id
        description
        gatsbyImageData(layout: CONSTRAINED)
      }
      awards
      awardsList {
        id
        caption
        logo {
          description
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      assessment {
        page {
          path
        }
        title
        contactStep
        shippmentStep
        form {
          email
          invalidEmail
          phone
          invalidPhone
          requiredField
          minChars
          maxChars
        }
        person
        companyName
        taxNumber
        truckType
        truckTypeOptions
        loadingFrom
        loadingFromOptions
        typeOfGoods
        loadingPlace
        deliveryPlace
        country
        countryOptions
        postalCode
        city
        additionalRequirements
        additionalRequirementsOptions
        comment
        error
        success
        send
        back
        next
      }
    }
  }
`
